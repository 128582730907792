<template>
  <section>
    <div class="flex flex-no-wrap">
      <Nav />

      <div class="p-10">

        <div class="row">
          <div class="col col-sm-4 text-start">
            <img
              src="@/assets/images/profile_pictures/profile_avatar_1.svg"
              class="profile-picture-grid"
            >
          </div>

          <div class="col-9 col-sm-8">
            <p class="fw-bold">
              Martha Walker
            </p>
            <p>
              Product Designer
            </p>
            <p>
              REI
            </p>
            <p>
              Seattle, WA
            </p>

            <img
              src="@/assets/images/icons/facebook_gray.svg"
              class="social-icons mt-4"
            >
            <img
              src="@/assets/images/icons/linkedin_gray.svg"
              class="social-icons mt-4"
            >
            <img
              src="@/assets/images/icons/twitter_gray.svg"
              class="social-icons mt-4"
            >

            <div class="d-flex text-start mt-4">
              <div class="ms-5">
                <span class="tag">New York</span><span class="tag">Venture Capital</span><span class="tag">Entrepreneurship</span><span class="tag">Parenting</span><span class="tag">Travel</span><span class="tag">Fine Dining</span>
              </div>
              <span class="text-uppercase fw-bold">45 Mutual Contacts</span>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
import Nav from '@/components/nav/nav.vue';
export default {
  name: 'MyNetwork',
  components: {
    Nav
  }
}
</script>

<style scoped src="@/css/bootstrap.min.css"></style>

<style scoped>
.enterprise-setup {
  background-color: #EDEDED;
}
.enterprise-setup h2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.enterprise-setup h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #142328;
  letter-spacing: 1px;
}
.enterprise-setup h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-top: 20px;
  max-height: 30px;
}
.enterprise-setup .container:first-child > .d-flex {
  border-bottom: 0.5px solid #332C54;
}
.logo {
  max-height: 33px!important;
}
.enterprise-setup img {
  height: 125px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.container-card {
  padding: 20px;
  margin-bottom: 30px;
  background-color: #FFFFFF;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
button {
  background-color: #FFFFFF!important;
  border: 1px solid #83C03B;
  box-sizing: border-box;
  border-radius: 30px;
  display: inline-block;
}
button:hover {
  color: #83C03B!important;
  border: 1px solid #83C03B!important;
}
a {
  text-decoration: none;
}
.tag {
  display: inline-block;
  padding: 5px;
  background: rgba(3, 29, 90, 0.2);
  border-radius: 3px;
  margin: 0px 5px;
  margin-bottom: 5px;
  color: #1E1E1E;
  font-size: 14px;
}
.tag.active {
  background: #83C03B;
  color: #F9F9F9;
}
.social-icons {
  padding-left: 0!important;
}
</style>
